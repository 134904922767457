import { Component, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import { TableOptions, DEFAULT_TABLE_OPTIONS } from "@/api/request";
import { SearchRequest } from "@/api/design-shop/request";
import DesignShopListGet, { ListItem } from "@/store/design-shop/search";
import MainShopGet from "@/store/main-shop/get";
import AppNameListGet from "@/store/app-name-list/get";
import Flash, { ErrorAlert } from "@/store/common/flash";

@Component({ components: { UlContentHeader, UlBreadcrumbs, UIDataTable } })
export default class Index extends Vue {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "アプリデザイン";
  headingSub = "App design";
  breadCrumbs = [
    { text: "アプリデザイン", disabled: true },
    { text: "店舗一覧", disabled: true }
  ];
  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "店舗ID", value: "id" },
    { text: "店舗名", value: "name" },
    { text: "アプリ名", value: "appName" },
    {
      label: "",
      text: "",
      value: "custom",
      sortable: false
    }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------

  // ローディングステータス
  isLoading = false;

  // 検索入力オプション
  inputOptions = {
    searchShopId: null as null | number,
    selectShopIds: null as null | number[],
    selectAppShopIds: null as null | number[]
  } as SearchRequest;

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions: TableOptions = DEFAULT_TABLE_OPTIONS;

  // ------------

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return DesignShopListGet.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return DesignShopListGet.getTotalCount;
  }

  get shopItems() {
    return MainShopGet.getItems;
  }

  get appItems() {
    return AppNameListGet.getItems;
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    await DesignShopListGet.restore(this.inputOptions as SearchRequest);
    const request = DesignShopListGet.getSearchRequest;
    // 検索入力オプション
    request.searchShopId &&
      (this.inputOptions.searchShopId = request.searchShopId);
    request.selectShopIds &&
      (this.inputOptions.selectShopIds = request.selectShopIds);
    request.selectAppShopIds &&
      (this.inputOptions.selectAppShopIds = request.selectAppShopIds);

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
    await this.search();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await DesignShopListGet.clearResponse();
    await MainShopGet.clearResponse();
    await AppNameListGet.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    await Flash.clear();
    await this.search();
  }

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async actionClickCallback(item: ListItem) {}

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async editClickCallback(item: ListItem) {}

  /**
   * テーブル行の確認ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async customClickCallback(item: ListItem) {
    await this.$router.push({
      name: "app-design-edit",
      params: { shopId: String(item.id) },
      query: { shopName: item.name, appName: item.appName, agreementsType: item.agreementsType }
    });
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    if (MainShopGet.isSuccess) {
      return true;
    }

    await MainShopGet.get();
    if (!MainShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: MainShopGet.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    return MainShopGet.isSuccess;
  }

  /**
   * アプリ名情報の一覧を取得する処理
   */
  async fetchAppList(): Promise<boolean> {
    if (AppNameListGet.isSuccess) {
      return true;
    }

    await AppNameListGet.get();
    if (!AppNameListGet.isSuccess) {
      await Flash.setErrorNow({
        message: AppNameListGet.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    return AppNameListGet.isSuccess;
  }

  /**
   * 検索処理
   */
  async search() {
    this.isLoading = true;
    let isSuccess = await this.fetchShopList();
    if (!isSuccess) {
      this.isLoading = false;
      return;
    }

    isSuccess = await this.fetchAppList();
    if (!isSuccess) {
      this.isLoading = false;
      return;
    }

    let request = this.createRequest();
    await DesignShopListGet.search(request);
    if (!DesignShopListGet.isSuccess) {
      await Flash.setErrorNow({
        message: DesignShopListGet.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }

    this.isLoading = false;
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SearchRequest {
    const request = this.tableOptions as SearchRequest;
    if (this.inputOptions.searchShopId) {
      request.searchShopId = this.inputOptions.searchShopId;
    } else {
      delete request.searchShopId;
    }

    if (this.inputOptions.selectShopIds) {
      request.selectShopIds = this.inputOptions.selectShopIds;
    } else {
      delete request.selectShopIds;
    }

    if (this.inputOptions.selectAppShopIds) {
      request.selectAppShopIds = this.inputOptions.selectAppShopIds;
    } else {
      delete request.selectAppShopIds;
    }

    return request;
  }
}
