import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=8ebbff5e&"
import script from "../../models/app-design/index.ts?vue&type=script&lang=ts&"
export * from "../../models/app-design/index.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports