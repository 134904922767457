import service from "@/api/service";
import { GetResponse } from "@/api/app-name-list/response";

/**
 * アプリ名情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get() {
  const response = await service.post("/get-app-name-list");
  return response.data as GetResponse;
}
