import service from "@/api/service";
import { GetResponse } from "@/api/main-shop/response";

/**
 * 本店店舗情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get(business_id : number) {
  const response = await service.post("/get-main-shop" ,{business_id : business_id});
  return response.data as GetResponse;
}
